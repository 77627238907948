<template>
  <div
    :class="[!dataLoaded && 'overlay overlay-block {{widgetClasses}}']"
    class="card"
  >
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1" :key="title">{{
          title
        }}</span>
        <span class="text-muted mt-1 fw-bold fs-7">{{ description }}</span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-3">
      <div
        class="overlay-layer rounded bg-white bg-opacity-5"
        :class="[dataLoaded && 'd-none']"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
        <!--begin::Table container-->
        <div class="table-responsive">
          <!--begin::Table-->
          <table
            class="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4"
          >
            <!--begin::Table head-->
            <thead>
              <tr class="border-0">
                <th class="p-0 w-40px"></th>
                <th class="p-0"></th>
                <th class="p-0 w-100px"></th>
                <th class="p-0 w-150px"></th>
                <th class="p-0 w-50px"></th>
                <th class="p-0 w-150px"></th>
                <th class="p-0 w-150px"></th>
                <th class="p-0 w-100px"></th>
                <th class="p-0"></th>
              </tr>
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody>
              <template v-for="(item, index) in items" :key="index">
                <tr :key="filters" v-if="(filters.status === 'All' || item.status.name === filters.status) && (filters.type === 'All' || item.type === filters.type)">
                  <td>
                    <!--begin::Symbol-->
                    <div class="symbol symbol-50px me-5">
                      <span
                        :class="`bg-primary`"
                        class="symbol-label text-light flex-column fw-bold fs-2 pt-1"
                      >
                          <span class="fs-8 text-light flex-row">{{ item.jobNo.prefix }}</span>
                          {{item.jobNo.code}}
                      </span>
                    </div>
                  </td>
                  <td>
                    <router-link :to="`/work-orders/view/${item.id}`" class="text-dark text-hover-primary mb-1 fs-6">{{ item.title }}</router-link><br>
                    <span class="text-muted fw-bold d-inline-block">{{
                      item.customer
                    }}</span>
                  </td>
                  <td class="text-start min-w-200px fs-8" v-html="item.assetTitle"></td>
                  <td class="text-start">
                    <span :class="`badge badge-primary`">{{
                        item.type
                      }}</span>
                  </td>
                  <td class="text-start">
                      <span class="bg-light-danger symbol-label"  data-bs-toggle="tooltip" v-if="item.chkPdfCount > 0"
                            title="Filled in checklist in PDF format is available"
                      >
                        <span class="svg-icon-danger svg-icon svg-icon-2x">
                          <inline-svg src="media/icons/duotone/Files/Compiled-file.svg" />
                        </span>
                      </span>
                  </td>
                  <td class="text-end text-primary fs-8">
                    {{ item.startDate }}<br>
                    {{ item.author }}
                  </td>
                  <td>
                    <div class="d-flex flex-column w-100 me-2">
                      <div class="d-flex flex-stack mb-2">
                        <span class="text-muted me-2 fs-7 fw-bold">
                          {{ item.progress.value }}%
                        </span>
                      </div>

                      <div class="progress h-6px w-100">
                        <div
                          :class="`progress-bar bg-${item.progress.color}`"
                          role="progressbar"
                          :style="`width: ${item.progress.value}%`"
                          :aria-valuenow="item.progress.value"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div><br>
                    <span v-html="item.assignedTo" class="fs-8"></span>
                  </td>
                  <td class="text-start text-primary fs-8" style="min-width: 120px">
                    {{ item.endDate }}
                  </td>
                  <td class="text-end">
                    <el-tooltip :content="`${item.status.description}`" placement="left">
                      <span :class="`badge badge-light-${item.status.color}`">{{
                        item.status.name
                      }}</span>
                    </el-tooltip>
                  </td>
                  <td class="text-end">


                    <router-link :to="`/work-orders/view/${item.id}`" class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
                      <!--begin::Svg Icon | path: -->
                      <span class="svg-icon svg-icon-2">
                        <inline-svg
                          src="media/icons/duotone/Navigation/Arrow-right.svg"
                        />
                      </span>
                      <!--end::Svg Icon-->
                    </router-link>
                  </td>
                </tr>
              </template>
            </tbody>
            <!--end::Table body-->
          </table>
        </div>
        <!--end::Table-->
      </div>
    <!--end::Body-->
  </div>
</template>

<script lang="ts">
import { inject, defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import {emitter} from "@/main";

export default defineComponent({
  name: "WorkOrdersList",
  components: {},
  props: {
    widgetClasses: String,
    status: String,
    type: String
  },
  setup() {
    /*return {
      itemsTabs,
      title,
      description
    };*/
  },
  data: function() {
    const filters = {status: "All", type: "All"};
    const items = [];
    const title = "Work orders list";
    const description = "";
    const dataLoaded = false;
    return {
      items,
      title,
      filters,
      dataLoaded,
      description
    };
  },
  created: function() {
    emitter.on("filterWorkOrdersList", (data) => {
      const filterDataFromEvent = JSON.parse(JSON.stringify(data));
      this.filters.status = filterDataFromEvent.status;
      this.filters.type = filterDataFromEvent.type;
      //console.log(filterDataFromEvent.status);
      //  this.filterTable();
    });
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
    /*setInterval(() =>{
      this.loadAJAXData();
    }, 4000);*/
  },
  methods: {
    /*filterTable() {
      this.items = this.items.filter((item) => {
        if (this.filters.status === "All" && this.filters.type === "All") {
          return true;
        } else if (this.filters.status === "All" && this.filters.type !== "All") {
          return item.type === this.filters.type;
        } else if (this.filters.status !== "All" && this.filters.type === "All") {
          return item.status === this.filters.status;
        } else {
          return item.status === this.filters.status && item.type === this.filters.type;
        }
      })
    },*/
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/work-orders/list")
        .then(data => {
          //update project tabs table
          this.title = "Work orders list";
          this.description = "You can filter work orders using filter button in the upper right corner";
          this.$nextTick(() => {
            this.items = data.data.data;
          });
          this.dataLoaded = true;
        })
        .catch(response => {
          //console.log(response);
        });
    }
  }
});
</script>
