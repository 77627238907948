
import { defineComponent, onMounted, onBeforeMount } from "vue";
import WorkOrdersList from "@/components/widgets/tables/WorkOrdersList.vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";

export default defineComponent({
  name: "list",
  components: {
    WorkOrdersList
  },
  methods: {
    filterProjectsListTable(options) {
      this.status = options.status;
      this.type = options.type;
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Work orders");
      setCurrentPageBreadcrumbs("Work orders", ["Work orders"]);
      setCurrentPageToolbarType("workOrdersList");
    });
  },
  data() {
    const status = "All";
    const type = "All";
    return {
      status,
      type
    }
  }
});
