<template>
  <!--begin::Work Orders List-->
  <div class="row g-5 gx-xxl-12">
    <div class="col-xxl-12">
      <WorkOrdersList
          :type="type"
          :status="status"
          @filterWorkOrdersTable="filterWorkOrdersTable"
          widget-classes="card-xxl-stretch mb-5 mb-xxl-12"
      ></WorkOrdersList>
    </div>
  </div>
  <!--end::Work Orders List-->
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from "vue";
import WorkOrdersList from "@/components/widgets/tables/WorkOrdersList.vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";

export default defineComponent({
  name: "list",
  components: {
    WorkOrdersList
  },
  methods: {
    filterProjectsListTable(options) {
      this.status = options.status;
      this.type = options.type;
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Work orders");
      setCurrentPageBreadcrumbs("Work orders", ["Work orders"]);
      setCurrentPageToolbarType("workOrdersList");
    });
  },
  data() {
    const status = "All";
    const type = "All";
    return {
      status,
      type
    }
  }
});
</script>
