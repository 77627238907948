
import { inject, defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import {emitter} from "@/main";

export default defineComponent({
  name: "WorkOrdersList",
  components: {},
  props: {
    widgetClasses: String,
    status: String,
    type: String
  },
  setup() {
    /*return {
      itemsTabs,
      title,
      description
    };*/
  },
  data: function() {
    const filters = {status: "All", type: "All"};
    const items = [];
    const title = "Work orders list";
    const description = "";
    const dataLoaded = false;
    return {
      items,
      title,
      filters,
      dataLoaded,
      description
    };
  },
  created: function() {
    emitter.on("filterWorkOrdersList", (data) => {
      const filterDataFromEvent = JSON.parse(JSON.stringify(data));
      this.filters.status = filterDataFromEvent.status;
      this.filters.type = filterDataFromEvent.type;
      //console.log(filterDataFromEvent.status);
      //  this.filterTable();
    });
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
    /*setInterval(() =>{
      this.loadAJAXData();
    }, 4000);*/
  },
  methods: {
    /*filterTable() {
      this.items = this.items.filter((item) => {
        if (this.filters.status === "All" && this.filters.type === "All") {
          return true;
        } else if (this.filters.status === "All" && this.filters.type !== "All") {
          return item.type === this.filters.type;
        } else if (this.filters.status !== "All" && this.filters.type === "All") {
          return item.status === this.filters.status;
        } else {
          return item.status === this.filters.status && item.type === this.filters.type;
        }
      })
    },*/
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/work-orders/list")
        .then(data => {
          //update project tabs table
          this.title = "Work orders list";
          this.description = "You can filter work orders using filter button in the upper right corner";
          this.$nextTick(() => {
            this.items = data.data.data;
          });
          this.dataLoaded = true;
        })
        .catch(response => {
          //console.log(response);
        });
    }
  }
});
